import { Box, styled } from "@mui/material";

export const StyledFormStatusIndicatorContainer = styled(Box)`
  border: 1px solid gray;
  min-height: 65px;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
`;
